@font-face {
	font-family: "Pretendard";
	font-weight: 900;
	font-display: swap;
	src: local("Pretendard Black"), url('../assets/fonts/Pretendard-Black.woff2') format("woff2"), url('../assets/fonts/Pretendard-Black.woff') format("woff");
  }
  
  @font-face {
	font-family: "Pretendard";
	font-weight: 800;
	font-display: swap;
	src: local("Pretendard ExtraBold"), url('../assets/fonts/Pretendard-ExtraBold.woff2') format("woff2"), url('../assets/fonts/Pretendard-ExtraBold.woff') format("woff");
  }
  
  @font-face {
	font-family: "Pretendard";
	font-weight: 700;
	font-display: swap;
	src: local("Pretendard Bold"), url('../assets/fonts/Pretendard-Bold.woff2') format("woff2"), url('../assets/fonts/Pretendard-Bold.woff') format("woff");
  }
  
  @font-face {
	font-family: "Pretendard";
	font-weight: 600;
	font-display: swap;
	src: local("Pretendard SemiBold"), url('../assets/fonts/Pretendard-SemiBold.woff2') format("woff2"), url('../assets/fonts/Pretendard-SemiBold.woff') format("woff");
  }
  
  @font-face {
	font-family: "Pretendard";
	font-weight: 500;
	font-display: swap;
	src: local("Pretendard Medium"), url('../assets/fonts/Pretendard-Medium.woff2') format("woff2"), url('../assets/fonts/Pretendard-Medium.woff') format("woff");
  }
  
  @font-face {
	font-family: "Pretendard";
	font-weight: 400;
	font-display: swap;
	src: local("Pretendard Regular"), url('../assets/fonts/Pretendard-Regular.woff2') format("woff2"), url('../assets/fonts/Pretendard-Regular.woff') format("woff");
  }
  
  @font-face {
	font-family: "Montserrat";
	font-weight: 800;
	src: local("Montserrat ExtraBold"), local("Montserrat-ExtraBold"), url('../assets/fonts/Montserrat-ExtraBold.woff2') format("woff2"), url('../assets/fonts/Montserrat-ExtraBold.woff') format("woff");
	unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
  }
  
  @font-face {
	font-family: "Montserrat";
	font-weight: 700;
	src: local("Montserrat Bold"), local("Montserrat-Bold"), url('../assets/fonts/Montserrat-Bold.woff2') format("woff2"), url('../assets/fonts/Montserrat-Bold.woff') format("woff");
	unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
  }
  
  @font-face {
	font-family: "Montserrat";
	font-weight: 600;
	src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"), url('../assets/fonts/Montserrat-SemiBold.woff2') format("woff2"), url('../assets/fonts/Montserrat-SemiBold.woff') format("woff");
	unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
  }
  
  @font-face {
	font-family: "Montserrat";
	font-weight: 500;
	src: local("Montserrat Medium"), local("Montserrat-Medium"), url('../assets/fonts/Montserrat-Medium.woff2') format("woff2"), url('../assets/fonts/Montserrat-Medium.woff') format("woff");
	unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
  }
  
  @font-face {
	font-family: "Montserrat";
	font-weight: 400;
	src: local("Montserrat Regular"), local("Montserrat-Regular"), url('../assets/fonts/Montserrat-Regular.woff2') format("woff2"), url('../assets/fonts/Montserrat-Regular.woff') format("woff");
	unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
  }
  
  @font-face {
	font-family: "Montserrat";
	font-weight: 300;
	src: local("Montserrat Light"), local("Montserrat-Light"), url('../assets/fonts/Montserrat-Light.woff2') format("woff2"), url('../assets/fonts/Montserrat-Light.woff') format("woff");
	unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
  }