.notiflix {
  word-break: keep-all;
}

/* notiflix report */
.notiflix-report div.notiflix-report-content div.notiflix-report-buttons a {
  border-radius: 5px !important;
}

/* notiflix confirm */
.notiflix-confirm div.notiflix-confirm-content div.notiflix-confirm-head h5 {
  border-color: #e9ebf1 !important;
  font-weight: 600 !important;
}

.notiflix-confirm div.notiflix-confirm-content {
  padding: 20px !important;
}

#NotiflixConfirmWrap.notiflix-confirm div.notiflix-confirm-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

#NotiflixConfirmWrap.notiflix-confirm div.notiflix-confirm-content div.notiflix-confirm-buttons a {
  min-width: 109px;
  width: auto;
  border-radius: 5px !important;
  padding: 12px 5px !important;
}

.notiflix-confirm div.notiflix-confirm-content div.notiflix-confirm-buttons a:hover {
  box-shadow: none !important;
}

.notiflix-confirm div.notiflix-confirm-content div.notiflix-confirm-buttons a.nx-confirm-button-ok,
.notiflix-confirm div.notiflix-confirm-content div.notiflix-confirm-buttons a.nx-confirm-button-cancel {
  margin: 0 !important;
}

.notiflix-confirm div.notiflix-confirm-content div.notiflix-confirm-buttons a.nx-confirm-button-ok {
  order: 2;
}

.notiflix-confirm div.notiflix-confirm-content div.notiflix-confirm-buttons a.nx-confirm-button-ok:hover {
  background-color: rgba(55, 125, 255, 0.8) !important;
}

.notiflix-confirm div.notiflix-confirm-content div.notiflix-confirm-buttons a.nx-confirm-button-cancel:hover {
  font-weight: 500 !important;
  color: #377dff !important;
}
